//
// _colorpicker.scss
//

.pcr-app {
  background: var(--#{$prefix}secondary-bg);
  box-shadow: $box-shadow-lg;
  border: 1px solid var(--#{$prefix}border-color);
  border-radius: 10px;
}

.pickr {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  overflow: hidden !important;
  transition: none !important;

  .pcr-button {
    border-radius: 1000;
    padding: 0;

    &::after,
    &::before {
      width: 40px !important;
      height: 40px !important;
      border-radius: 100% !important;
      border: 2px solid #ccc
    }

  }
}

.pcr-app {
  .pcr-interaction {
    .pcr-type.active {
      background: $primary;
    }

    .pcr-result {
      background-color: $input-bg;
      color: $input-color;
      border: 1px solid $input-border-color;
      border-radius: $input-border-radius;
      flex: auto !important
    }

    input {
      border-radius: $input-border-radius !important;

      &:focus {
        box-shadow: none;
        background-color: $input-focus-bg;
        border-color: $input-focus-border-color;
      }
    }

    .pcr-save {
      background: $success !important;
    }

    .pcr-clear,
    .pcr-cancel {
      background: $warning !important;
      margin-right: 0;
    }
  }
}