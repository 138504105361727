.CSVImporter_FileStep__header,
.CSVImporter_FormatRawPreview__scroll {
  display: none !important
}

.CSVImporter_FileSelector,
.CSVImporter_ImporterFrame {
  border: 0.2em dashed !important;
  border-color: var(--#{$prefix}light-text-emphasis) !important;
}

.CSVImporter_TextButton {
  color: var(--#{$prefix}white) !important;
  background-color: var(--#{$prefix}success) !important;
  border: var(--#{$prefix}-btn-border-width) solid var(--#{$prefix}-btn-border-color) !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;

  &:hover {
    background-color: var(--#{$prefix}success-text-emphasis) !important;
  }
}

.CSVImporter_ProgressDisplay__progressBarIndicator {
  border-radius: 10px !important;
  background-color: var(--#{$prefix}success) !important;
}

.CSVImporter_ColumnDragSourceArea {
  padding: 1.5em 0
}

.CSVImporter_ColumnDragSourceArea__pageIndicator {
  top: -1.5em !important
}

.CSVImporter_ColumnDragTargetArea {
  border-top: 0.2em dashed !important;
  border-color: var(--#{$prefix}light-text-emphasis) !important;
  padding-top: 1em;
}