.auth-one-bg {
  // background-image: url("../../images/auth-one-bg.jpg");
  background-position: center;
  background-size: cover;

  .bg-overlay {
    background: linear-gradient(to right, darken($primary, 6%), $primary);
    opacity: 1;
  }
}

.w-25px {
  width: 25px;
}

@media (max-width: 768px) {

  .w-200,
  .w-300,
  .w-400,
  .w-500,
  .w-600 {
    width: 100%
  }
}

@media (min-width: 768px) {
  .w-200 {
    min-width: 200px
  }

  .w-300 {
    min-width: 300px
  }

  .w-400 {
    min-width: 400px
  }

  .w-500 {
    min-width: 500px
  }

  .w-600 {
    min-width: 600px
  }
}

.custom-invalid {
  background-color: lighten($invalid, 30%);
  color: darken($invalid, 15%);
}

.rotate {
  animation: rotation 2s infinite linear;
}

.transition {
  transition: all 0.3s;
}

.rotate180deg {
  transform: rotate(-180deg);
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.cm-focused {
  outline: none !important;
}

.offcanvas-drawer {
  border-left: 0 !important;

  .close-btn {
    width: 40px;
    height: 40px;
    position: absolute;
    left: -60px;
    top: 20px;
    border-radius: 60px;
    line-height: 0 !important;
  }

  .label-btn {
    display: none;
  }
}

@media (max-width: 1250px) {
  .offcanvas-drawer {
    width: 100% !important;
    background: transparent;

    .close-btn {
      font-weight: 500;
      width: 100px;
      height: 40px;
      position: absolute;
      padding: 20px;
      left: 20px;
      top: 20px;
      border-radius: 60px;
    }

    .label-btn {
      color: white;
      display: inline;
    }
  }
}

.toolbar-container {
  width: 50%;
}

@media (max-width: 1250px) {
  .toolbar-container {
    width: 100%;
  }
}


// Collapsible titles (text with line before and after)
// -----------------------------------------------

.filter-header-collapsible {
  width: 100%;
  border-bottom: 1px solid #e9ebec;
  line-height: 0.1em;
  height: 0;
}

.filter-header-collapsible span {
  cursor: pointer;
  background-color: var(--#{$prefix}secondary-bg);
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.8em;
  margin-left: 20px;
  padding: 0 10px;
}

.filter-header-collapsible span:after {
  content: "\2023";
  font-size: 30px;
  vertical-align: middle;
  margin-left: 5px;
  display: inline-block;
  transition: all 0.3s;
}

.filter-header-collapsible[data-opened=true] span:after {
  transform: rotate(90deg) translateX(-1px) translateY(1px);
}

.filter-drawer-wrapper {

  .filter-drawer-group-wrapper {
    border-top: 1px solid #EAECED;

    &:first-child {
      border-top: 0
    }

  }
}

.filter-item {
  border-top: 1px dashed #D8DADA;
  padding-top: 20px;

  &:first-child {
    padding-top: 0;
    border-top: 0
  }
}

.switch-group-error {
  .switch-label {
    color: rgba(var(--vz-danger-rgb), var(--vz-text-opacity));
  }

  .form-check-input:checked {
    border-color: rgba(var(--vz-danger-rgb), var(--vz-text-opacity));
    background-color: rgba(var(--vz-danger-rgb), var(--vz-text-opacity));
  }
}

.col-80-at-1700 {
  transition: all 0.3s;
}

@media (min-width: 1200px) and (max-width: 1700px) {
  .col-80-at-1700 {
    transition: all 0.3s;
    width: 80%;
  }
}