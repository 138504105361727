// 
// _card.scss
// 

.card {
    margin-bottom: 1rem;
    box-shadow: $card-box-shadow;
}


.card-header {
    border-bottom: 1px solid var(--#{$prefix}border-color);

}

//Card Header Dropdown
.card-header-dropdown {
    .dropdown-btn {
        padding: $card-cap-padding-y 0;
    }
}

.card-footer {
    border-top: 1px solid var(--#{$prefix}border-color);
}

.card-title {
    font-size: 16px;
    margin: 0 0 7px 0;
}

.card-height-100 {
    height: calc(100% - #{$grid-gutter-width});
}

.card-animate {
    transition: all 0.4s;

    &:hover {
        transform: translateY(calc(#{-$grid-gutter-width} / 5));
        box-shadow: $box-shadow-lg;
    }
}


// card color variant

@each $color,
$value in $theme-colors {
    .card-#{$color} {
        background-color: $value;
        color: color-contrast($value);

        .card-header,
        .card-footer {
            background-color: rgba($white, 0.1);
            color: color-contrast($value);
            border-color: transparent;
        }

        .card-title {
            color: color-contrast($value);
        }
    }
}

@each $color,
$value in $theme-colors {
    .card-border-#{$color} {
        border-color: $value !important;

        .card-header,
        .card-footer {
            border-color: $value;
        }

    }
}

.card-light {
    background-color: var(--#{$prefix}light);

    .card-header,
    .card-footer {
        color: var(--#{$prefix}body-color) !important;
        background-color: rgba(var(--#{$prefix}dark-rgb), 0.1);
    }

    .card-title,
    .card-text {
        color: var(--#{$prefix}body-color) !important;
    }
}

// Loader

.card-preloader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(var(--#{$prefix}light-rgb), 0.6);
    z-index: 9999;
}

.card-status {
    width: 40px;
    height: 40px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) !important;
}

.custom-loader {
    animation: spin 2s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg)
    }
}

.card-overlay {
    position: relative;
    overflow: hidden;

    &:before {
        content: "";
        background-color: rgba($primary, 0.20);
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
    }

    :is(.card-header, .card-footer) {
        border-color: rgba($white, 0.15) !important;
    }
}

.card-toolbar-menu {
    line-height: 0.8;

    a {
        font-size: 17px;
    }

    .minimize-card {
        .plus {
            display: none;
        }

        .minus {
            display: block;
        }

        &.collapsed {
            .plus {
                display: block;
            }

            .minus {
                display: none;
            }
        }
    }
}


.card-responsive {
    span {
        text-transform: uppercase;
    }

    h6 {
        display: flex;
        align-items: center;

        i {
            font-size: 1rem;
        }
    }

    a {
        h6 {
            font-size: 10px;

            i {
                font-size: .825rem;
            }
        }
    }
}


@media (min-width: 1200px) and (max-width: 1440px) {
    .card-responsive {

        h6 {
            font-size: x-small;
        }
    }

    .card-body-responsive {
        h4 {
            font-size: 20px !important;
        }
    }
}



@media (min-width: 1200px) and (max-width: 1280px) {

    .card-summary {
        .card-header {
            padding: 10px 13px
        }
    }


    .card-responsive {
        align-items: flex-start !important;

        h6 {
            font-size: 11px;
            font-weight: 700;

            i {
                font-size: 12px;
            }
        }

        a {
            h6 {
                font-size: 9px;
                font-weight: 600;

            }
        }
    }

    .card-body-responsive {
        h4 {
            font-size: 18px !important;
            font-weight: 700;
        }

        h6 {
            font-size: 10px;

        }

        .avatar-responsive {
            width: 2rem;
            height: 2rem;
        }
    }
}